<template>
	<div class="tooltip" role="tooltip" ref="tooltip">
		<span class="arrow"></span>
		<slot></slot>
	</div>
</template>

<script>
	import { createPopper } from '@popperjs/core';

	export default {
		props: {
			triggerElSelector: {
				type: String,
				required: true,
			},
			placement: {
				type: String,
				default: 'top',
			},
		},
		methods: {
			initTooltip() {
				createPopper(this.triggerElement, this.$refs.tooltip, {
					placement: this.placement,
				});

				const showEvents = ['mouseenter', 'focus'];
				const hideEvents = ['mouseleave', 'blur'];

				showEvents.forEach((event) => {
					this.triggerElement.addEventListener(event, this.show);
				});

				hideEvents.forEach((event) => {
					this.triggerElement.addEventListener(event, this.hide);
				});
			},
			show() {
				if (!this.$refs.tooltip) return;
				this.$refs.tooltip.classList.add('show');
			},
			hide() {
				if (!this.$refs.tooltip) return;
				this.$refs.tooltip.classList.remove('show');
			},
		},
		computed: {
			triggerElement() {
				return document.querySelector(this.triggerElSelector);
			},
		},
		mounted() {
			this.initTooltip();
		},
	};
</script>

<style></style>
