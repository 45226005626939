<template>
	<div class="content-header mb-4">
		<div class="d-flex align-items-start">
			<h1 class="h2 mb-0 me-3" id="header-bread-title">{{ title }}</h1>
			<ElTooltip
				v-if="isTitleAbbreviated"
				:trigger-el-selector="`#header-bread-title`"
				placement="bottom"
			>
				{{ breadTitle }}
			</ElTooltip>

			<slot name="header-tags"></slot>

			<div class="content-header-buttons d-flex ms-auto">
				<div class="container d-flex justify-content-end h-header">
					<ViewHeaderActions
						:buttons-action="buttonsAction"
						:buttons-resource="buttonsResource"
						:need-confirm-deletion="needConfirmDeletion"
					/>

					<slot></slot>
				</div>
			</div>
		</div>
		<slot name="extra-info-header"></slot>
	</div>
</template>

<script>
	import ElTooltip from '@/components/ui/ElTooltip.vue';
	import ViewHeaderActions from '@/components/ViewHeaderActions.vue';

	export default {
		name: 'ViewHeader',
		components: {
			ViewHeaderActions,
			ElTooltip,
		},
		props: {
			breadTitle: {
				type: String,
				default() {
					return '';
				},
			},
			buttonsAction: {
				type: Array,
				default() {
					return [];
				},
			},
			buttonsResource: {
				type: String,
				default: '',
			},
			needConfirmDeletion: {
				type: Boolean,
				default: false,
			},
			abbreviateTitleAt: {
				type: Number,
				default() {
					return -1;
				},
			},
		},
		computed: {
			title() {
				if (!this.isTitleAbbreviated) return this.breadTitle;
				return `${this.breadTitle.slice(0, this.abbreviateTitleAt)}...`;
			},
			isTitleAbbreviated() {
				return (
					this.abbreviateTitleAt >= 0 &&
					this.abbreviateTitleAt < this.breadTitle.length
				);
			},
		},
		mounted() {
			const observer = new IntersectionObserver(
				([e]) =>
					e.target.classList.toggle('stuck', e.intersectionRatio < 1),
				{ threshold: [1] }
			);

			this.$nextTick(() => {
				observer.observe(document.querySelector('.content-header'));
			});
		},
	};
</script>

<style>
	.tags-collection + .content-header-buttons {
		margin-left: 0 !important;
	}
</style>
